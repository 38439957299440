import React from "react";
import "./indicadores.css";

export default function Indicadores() {
  // Manually input your data
  const data = [
    {
        "nombre": "Padron de usuarios",
        "total": 265622
    },
    {
        "nombre": "Familias apoyadas",
        "total": 102030
    },
    {
        "nombre": "Usuarios CAASIM en linea",
        "total": 217078
    }
];

  return (
    <div>
      <h3 className="title">INDICADORES</h3>
      <hr className="hr-gob" />
      <div className="myContainer">
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <div className="myColumn">{item.nombre}</div>
            <div className="myColumn h5 mb-0">
              <strong>{item.total.toLocaleString()}</strong>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
