export const years = [
  {
    year: 2024,
    content: [
      {
        trimester: "Primer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2024/Informe-Primer-Trimestre-2024.pdf",
      },
      {
        trimester: "Segundo Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2024/Informe-Segundo-Trimestre-2024.pdf",
      },
      {
        trimester: "Tercer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2024/Informe-Tercer-Trimestre-2024.pdf",
      },
      // {
      //   trimester: "Cuarto Trimestre",
      //   link: "/CAASIM/informe-de-gasto-federalizado/2024/Informe-Cuarto-Trimestre-2024.pdf",
      // },
      // {
      //   trimester: "Informe Definitivo",
      //   link: "/CAASIM/informe-de-gasto-federalizado/2024/Informe-Definitivo-2024.pdf",
      // },
    ],
  },
  {
    year: 2023,
    content: [
      {
        trimester: "Primer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2023/Informe-Primer-Trimestre-2023.pdf",
      },
      {
        trimester: "Segundo Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2023/Informe-Segundo-Trimestre-2023.pdf",
      },
      {
        trimester: "Tercer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2023/Informe-Tercer-Trimestre-2023.pdf",
      },
      {
        trimester: "Cuarto Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2023/Informe-Cuarto-Trimestre-2023.pdf",
      },
      {
        trimester: "Informe Definitivo",
        link: "/CAASIM/informe-de-gasto-federalizado/2023/Informe-Definitivo-2023.pdf",
      },
    ],
  },
  {
    year: 2022,
    content: [
      {
        trimester: "Primer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2022/Informe-Primer-Trimestre-2022.pdf",
      },
      // {
      //   trimester: "Segundo Trimestre",
      //   link: "/CAASIM/informe-de-gasto-federalizado/2022/Informe-Segundo-Trimestre-2022.pdf",
      // },
      {
        trimester: "Tercer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2022/Informe-Tercer-Trimestre-2022.pdf",
      },
      {
        trimester: "Cuarto Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2022/Informe-Cuarto-Trimestre-2022.pdf",
      },
      {
        trimester: "Informe Definitivo",
        link: "/CAASIM/informe-de-gasto-federalizado/2022/Informe-Definitivo-2022.pdf",
      },
    ],
  },
  {
    year: 2021,
    content: [
      {
        trimester: "Primer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2021/Informe-Primer-Trimestre-2021.pdf",
      },
      {
        trimester: "Segundo Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2021/Informe-Segundo-Trimestre-2021.pdf",
      },
      {
        trimester: "Tercer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2021/Informe-Tercer-Trimestre-2021.pdf",
      },
      {
        trimester: "Cuarto Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2021/Informe-Cuarto-Trimestre-2021.pdf",
      },
    ],
  },
  {
    year: 2020,
    content: [
      {
        trimester: "Primer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2020/Informe-Primer-Trimestre-2020.pdf",
      },
      {
        trimester: "Segundo Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2020/Informe-Segundo-Trimestre-2020.pdf",
      },
      {
        trimester: "Tercer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2020/Informe-Tercer-Trimestre-2020.pdf",
      },
      {
        trimester: "Cuarto Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2020/Informe-Cuarto-Trimestre-2020.pdf",
      },
    ],
  },
  {
    year: 2019,
    content: [
      {
        trimester: "Primer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2019/Informe-Primer-Trimestre-2019.pdf",
      },
      {
        trimester: "Segundo Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2019/Informe-Segundo-Trimestre-2019.pdf",
      },
      {
        trimester: "Tercer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2019/Informe-Tercer-Trimestre-2019.pdf",
      },
      {
        trimester: "Cuarto Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2019/Informe-Cuarto-Trimestre-2019.pdf",
      },
    ],
  },
  {
    year: 2018,
    content: [
      {
        trimester: "Primer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2018/Informe-Primer-Trimestre-2018.pdf",
      },
      {
        trimester: "Segundo Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2018/Informe-Segundo-Trimestre-2018.pdf",
      },
      {
        trimester: "Tercer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2018/Informe-Tercer-Trimestre-2018.pdf",
      },
      {
        trimester: "Cuarto Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2018/Informe-Cuarto-Trimestre-2018.pdf",
      },
    ],
  },
  {
    year: 2017,
    content: [
      {
        trimester: "Primer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2017/Informe-Primer-Trimestre-2017.p18.pdf",
      },
      {
        trimester: "Segundo Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2017/Informe-Segundo-Trimestre-2017.p18.pdf",
      },
      {
        trimester: "Tercer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2017/Informe-Tercer-Trimestre-2017.p18.pdf",
      },
      {
        trimester: "Cuarto Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2017/Informe-Cuarto-Trimestre-2017.p18.pdf",
      },
    ],
  },
  {
    year: 2016,
    content: [
      {
        trimester: "Primer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2016/Informe-Primer-Trimestre-2016.pdf",
      },
      {
        trimester: "Segundo Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2016/Informe-Segundo-Trimestre-2016.pdf",
      },
      {
        trimester: "Tercer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2016/Informe-Tercer-Trimestre-2016.pdf",
      },
      {
        trimester: "Cuarto Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2016/Informe-Cuarto-Trimestre-2016.pdf",
      },
    ],
  },
  {
    year: 2015,
    content: [
      {
        trimester: "Tercer Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2015/Informe-Tercer-Trimestre-2015.pdf",
      },
      {
        trimester: "Cuarto Trimestre",
        link: "/CAASIM/informe-de-gasto-federalizado/2015/Informe-Cuarto-Trimestre-2015.pdf",
      },
    ],
  },
];
