export const inv01 = [
  {
    nombre: "Bases CAASIM-HGO-INV3-OP-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-01-2024/BASES CAASIM-HGO-INV3-OP-01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-INV3-OP-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-01-2024/CATALOGO MUDO CAASIM-HGO-INV3-OP-01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-INV3-OP-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-01-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-INV3-OP-01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-INV3-OP-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-01-2024/JUNTA DE ACLARACIONES CAASIM-HGO-INV3-OP-01-2024001.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura CAASIM-HGO-INV3-OP-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-01-2024/PRESENTACIÓN Y APERTURA DE PROPOSICIONES CAASIM-HGO-INV3-OP-01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

// Create 10 dictionaries for inv-02 to inv-10 and inside of them create a structure for bases and catalogo mudo

export const inv02 = [
  {
    nombre: "Bases CAASIM-HGO-INV3-OP-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-02-2024/BASES CAASIM-HGO-INV3-OP-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-INV3-OP-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-02-2024/CATALOGO MUDO CAASIM-HGO-INV3-OP-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-INV3-OP-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-02-2024/JUNTA DE ACLARACIONES CAASIM-HGO-INV3-OP-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  // Creaate for Presentación y Apertura
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-INV3-OP-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-02-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-INV3-OP-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  // Create for Fallo
  {
    nombre: "Fallo CAASIM-HGO-INV3-OP-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-02-2024/FALLO CAASIM-HGO-INV3-OP-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const inv03 = [
  {
    nombre: "Bases CAASIM-HGO-INV3-OP-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-03-2024/BASES CAASIM-HGO-INV3-OP-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-INV3-OP-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-03-2024/CATALOGO MUDO CAASIM-HGO-INV3-OP-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  // Create for Junta de Aclaraciones
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-INV3-OP-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-03-2024/JUNTA DE ACLARACIONES CAASIM-HGO-INV3-OP-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  // Creaate for Presentación y Apertura
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-INV3-OP-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-03-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-INV3-OP-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  // Create for Fallo
  {
    nombre: "Fallo CAASIM-HGO-INV3-OP-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-03-2024/FALLO CAASIM-HGO-INV3-OP-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const inv04 = [
  {
    nombre: "Bases CAASIM-HGO-INV3-OP-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-04-2024/BASES CAASIM-HGO-INV3-OP-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-INV3-OP-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-04-2024/CATALOGO MUDO CAASIM-HGO-INV3-OP-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  // Create for Junta de Aclaraciones
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-INV3-OP-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-04-2024/JUNTA DE ACLARACIONES CAASIM-HGO-INV3-OP-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  // Creaate for Presentación y Apertura
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-INV3-OP-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-04-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-INV3-OP-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const inv05 = [
  {
    nombre: "Bases CAASIM-HGO-INV3-OP-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-05-2024/BASES CAASIM-HGO-INV3-OP-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-INV3-OP-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-05-2024/CATALOGO MUDO CAASIM-HGO-INV3-OP-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  // Create for Junta de Aclaraciones
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-INV3-OP-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-05-2024/JUNTA DE ACLARACIONES CAASIM-HGO-INV3-OP-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  // Creaate for Presentación y Apertura
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-INV3-OP-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-05-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-INV3-OP-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  // Create for Fallo
  {
    nombre: "Fallo CAASIM-HGO-INV3-OP-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-05-2024/FALLO CAASIM-HGO-INV3-OP-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const inv06 = [
  {
    nombre: "Bases CAASIM-HGO-INV3-OP-06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-06-2024/BASES CAASIM-HGO-INV3-OP-06-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-INV3-OP-06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-06-2024/CATALOGO MUDO CAASIM-HGO-INV3-OP-06-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Junta de Aclaraciones
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-INV3-OP-06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-06-2024/JUNTA DE ACLARACIONES CAASIM-HGO-INV3-OP-06-2024.pdf",
          },
        ],
      },
    ],
  },
  // Creaate for Presentación y Apertura
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-INV3-OP-06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-06-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-INV3-OP-06-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Fallo
  {
    nombre: "Fallo CAASIM-HGO-INV3-OP-06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-06-2024/FALLO CAASIM-HGO-INV3-OP-06-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const inv07 = [
  {
    nombre: "Bases CAASIM-HGO-INV3-OP-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-07-2024/BASES CAASIM-HGO-INV3-OP-07-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-INV3-OP-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-07-2024/CATALOGO MUDO CAASIM-HGO-INV3-OP-07-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Junta de Aclaraciones
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-INV3-OP-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-07-2024/JUNTA DE ACLARACIONES CAASIM-HGO-INV3-OP-07-2024.pdf",
          },
        ],
      },
    ],
  },
  // Creaate for Presentación y Apertura
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-INV3-OP-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-07-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-INV3-OP-07-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Fallo
  {
    nombre: "Fallo CAASIM-HGO-INV3-OP-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-07-2024/FALLO CAASIM-HGO-INV3-OP-07-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const inv08 = [
  {
    nombre: "Bases CAASIM-HGO-INV3-OP-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-08-2024/BASES CAASIM-HGO-INV3-OP-08-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-INV3-OP-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-08-2024/CATALOGO MUDO CAASIM-HGO-INV3-OP-08-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Junta de Aclaraciones
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-INV3-OP-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-08-2024/JUNTA DE ACLARACIONES CAASIM-HGO-INV3-OP-08-2024.pdf",
          },
        ],
      },
    ],
  },
  // Creaate for Presentación y Apertura
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-INV3-OP-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-08-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-INV3-OP-08-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Fallo
  {
    nombre: "Fallo CAASIM-HGO-INV3-OP-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-08-2024/FALLO CAASIM-HGO-INV3-OP-08-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const inv09 = [
  {
    nombre: "Bases CAASIM-HGO-INV3-OP-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-09-2024/BASES CAASIM-HGO-INV3-OP-09-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-INV3-OP-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-09-2024/CATALOGO MUDO CAASIM-HGO-INV3-OP-09-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Junta de Aclaraciones
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-INV3-OP-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-09-2024/JUNTA DE ACLARACIONES CAASIM-HGO-INV3-OP-09-2024.pdf",
          },
        ],
      },
    ],
  },
  // Creaate for Presentación y Apertura
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-INV3-OP-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-09-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-INV3-OP-09-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const inv10 = [
  {
    nombre: "Bases CAASIM-HGO-INV3-OP-10-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-10-2024/BASES CAASIM-HGO-INV3-OP-10-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-INV3-OP-10-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-10-2024/CATALOGO MUDO CAASIM-HGO-INV3-OP-10-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Junta de Aclaraciones
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-INV3-OP-10-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-10-2024/JUNTA DE ACLARACIONES CAASIM-HGO-INV3-OP-10-2024.pdf",
          },
        ],
      },
    ],
  },
  // Creaate for Presentación y Apertura
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-INV3-OP-10-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-10-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-INV3-OP-10-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const itp01 = [
  {
    nombre: "Bases CAASIM-HGO-ITP3-OP-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/BASES INVITACION ITP-01-2024_AUDITORIA.pdf",
          },
        ],
      },
    ],
  },
  // Create for Junta de Aclaraciones
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-ITP3-OP-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/Junta de Aclaraciones ITP-CAASIM-SRMYSG-01-2024.pdf",
          },
        ],
      },
    ],
  },
  // I have a list of 12 files that I need to add here, I will give you the list and you will add them, giving then proper namees, following best practices ['Acta de Cierre de Auditoría Externa_2024.pdf', 'Acta de Inicio_AE_2024.pdf', 'Formato Cronograma_2024.pdf', 'Formato de Cédula de Observaciones 2024.pdf', 'Formato de Evaluación 2024.pdf', 'Formato_Carta Planeación_2024.pdf', 'Formato_Oficio de notificación de resultados a OIC_2024_G.pdf', 'Formato_Oficio de notificación de resultados_ ENTE_2024.pdf', 'Formato_Orden de Auditoría_2024.pdf', 'Modelo de Contrato 2024_s.pdf', 'Observaciones _Finales_ 2024.pdf', 'Oficio NO SOLVENTO_ área investigadora_o_Autoridad_Competente_2024_G.pdf']

  {
    nombre: "Acta de Cierre de Auditoría Externa",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/Acta de Cierre de Auditoría Externa_2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Acta de Inicio Auditoría Externa",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/Acta de Inicio_AE_2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Formato Cronograma",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/Formato Cronograma_2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Formato de Cédula de Observaciones",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/Formato de Cédula de Observaciones 2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Formato de Evaluación",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/Formato de Evaluación 2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Formato Carta Planeación",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/Formato_Carta Planeación_2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Formato Oficio de notificación de resultados a OIC",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/Formato_Oficio de notificación de resultados a OIC_2024_G.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Formato Oficio de notificación de resultados ENTE",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/Formato_Oficio de notificación de resultados_ ENTE_2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Formato Orden de Auditoría",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/Formato_Orden de Auditoría_2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Modelo de Contrato",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/Modelo de Contrato 2024_s.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Observaciones Finales",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/Observaciones _Finales_ 2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Oficio NO SOLVENTO área investigadora o Autoridad Competente",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/Oficio NO SOLVENTO_ área investigadora_o_Autoridad_Competente_2024_G.pdf",
          },
        ],
      },
    ],
  },
  // Create for Presentación y Apertura
  {
    nombre: "Presentación y Apertura de Proposiciones",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/APERTURA ITP-01-2024 AUDITORIA.pdf",
          },
        ],
      },
    ],
  },
  // Create for Fallo
  {
    nombre: "Fallo",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-01-2024/FALLO ITP-CAASIM-SRMYSG-DL-01-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const inv11 = [
  {
    nombre: "Bases CAASIM-HGO-INV3-OP-11-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-11-2024/BASES CAASIM-HGO-INV3-OP-11-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-INV3-OP-11-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-11-2024/CATALOGO MUDO CAASIM-HGO-INV3-OP-11-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-INV3-OP-11-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-11-2024/JUNTA DE ACLARACIONES CAASIM-HGO-INV3-OP-11-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-INV3-OP-11-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-11-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-INV3-OP-11-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for fe de erratas
  {
    nombre: "Fe de Erratas CAASIM-HGO-INV3-OP-11-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-11-2024/FE DE ERRATAS CAASIM-HGO-INV3-OP-11-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo CAASIM-HGO-INV3-OP-11-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-11-2024/FALLO CAASIM-HGO-INV3-OP-11-2024.pdf",
          },
        ],
      },
    ],
  }
];

export const itp02 = [
  {
    nombre: "Bases INVITACION ITP-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-02-2024/BASES INVITACION ITP-02-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Junta de Aclaraciones
  {
    nombre: "Junta de Aclaraciones INVITACION ITP-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-02-2024/JUNTA DE ACLARACIONES ITP-CAASIM-SRMYSG-DL-02-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones INVITACION ITP-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/invitaciones/2024/INVITACION ITP-02-2024/APERTURA ITP-02-2024.pdf",
          },
        ],
      },
    ],
  }
];