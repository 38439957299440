import Card from "../Card/Card";

const ProtocoloCero = () => {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">Protocolo Cero</h3>
        <hr className="hr-gob" />
      </div>
      <div>
        <h4 className="title">Descripción</h4>
        <p className="mb-4">El Protocolo Cero establece medidas específicas para prevenir conductas de acoso sexual y/o laboral y hostigamiento sexual y/o laboral en las Dependencias y Entidades de la Administración Pública Estatal, así como promover una cultura Institucional de igualdad de género y un clima laboral libre de violencia.</p>
      </div>
      <div className="row">
        <Card
          document="Acuerdo que Contiene el Protocolo Cero"
          title="Acuerdo"
          href="/CAASIM/protocolo-cero/Convocatoria Protocolo Cero.pdf"
          year="2024"
          type="PDF"
        />
        <Card
          document="Convocatoria Consejeros 2024"
          title="Convocatoria"
          href="/CAASIM/protocolo-cero/Convocatoria 2024.pdf"
          year="2024"
          type="PDF"
        />
        <Card
          document="Convocatoria Protocolo Cero"
          title="Documento"
          href="/CAASIM/protocolo-cero/Convocatoria Protocolo Cero.pdf"
        />
        <Card
          document="Directorio Consejeros Protocolo Cero"
          title="Documento"
          href="/CAASIM/protocolo-cero/Directorio Consejeros Protocolo Cero.pdf"
        />
        <Card
          document="Pronunciamiento Cero Tolerancia"
          title="Documento"
          href="/CAASIM/protocolo-cero/Pronunciamiento Cero Tolerancia.pdf"
        />
      </div>
    </div>
  );
};

export default ProtocoloCero;
