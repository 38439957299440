import React from "react";
import "./catalogoBMeI.css";

function CatalogoBMeI() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">CATALOGO DE BIENES MUEBLES E INMUEBLES</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
      {/* SECTION 2024 - Tercer Trime4tre */}
        <div className="col-md-4 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2024</h5>
              <h6>Tercer Trimestre</h6>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Muebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2024/3er Trimestre/CATALOGO DE BIENES MUEBLES TERCER TRIMESTRE 2024 (PUBLICACIÓN).xls"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Inmuebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2024/3er Trimestre/CATALOGO DE BIENES INMUEBLES TERCER TRIMESTRE 2024 (PUBLICACIÒN).xlsx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* SECTION 2024 - Segundo Trime4tre */}
        <div className="col-md-4 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2024</h5>
              <h6>Segundo Trimestre</h6>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Muebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2024/2do Trimestre/CATALOGO DE BIENES MUEBLES SEGUNDO TRIMESTRE 2024 (PUBLICACIÓN).xls"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Inmuebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2024/2do Trimestre/CATALOGO DE BIENES INMUEBLES SEGUNDO TRIMESTRE 2024 (PUBLICACIÒN).xlsx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* SECTION 2024 - Primer Trime4tre */}
        <div className="col-md-4 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2024</h5>
              <h6>Primer Trimestre</h6>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Muebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2024/1er Trimestre/CATALOGO DE BIENES MUEBLES PRIMER TRIMESTRE 2024 PUBLICACIÒN.xls"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Inmuebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2024/1er Trimestre/CATALOGO DE BIENES INMUEBLES PRIMER TRIMESTRE 2024 PUBLICACIÒN.xlsx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* SECTION 2023 - Cuarto Trimestre */}
        <div className="col-md-4 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2023</h5>
              <h6>Cuarto Trimestre</h6>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Muebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2023/4to Trimestre/RELACION DE BIENES MUEBLES CUARTO TRIMESTRE 2023.xls"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Inmuebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2023/4to Trimestre/RELACION DE BIENES INMUEBLES CUARTO TRIMESTRE 2023.xlsx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* SECTION 2023 - Tercer Trimestre */}
        <div className="col-md-4 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2023</h5>
              <h6>Tercer Trimestre</h6>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Muebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2023/3er Trimestre/CATALOGO DE BIENES MUEBLES TERCER TRIMESTRE 2023 (PUBLICACIÓN).xls"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Inmuebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2023/3er Trimestre/CATÁLOGO DE BIENES INMUEBLES AL 30 DE SEPTIEMBRE 2023 ( PUBLICACIÓN).xlsx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* SECTION 2023 - Segundo Trimestre */}
        <div className="col-md-4 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2023</h5>
              <h6>Segundo Trimestre</h6>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Muebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2023/2do Trimestre/PUBLICACION DE BIENES MUEBLES SEGUNDO TRIMESTRE 2023..xls"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Inmuebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2023/2do Trimestre/PUBLICACION DE BIENES INMUEBLES AL 30 DE JUNIO 2023.xlsx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* SECTION 2023 - Primer Trimestre */}
        <div className="col-md-4 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2023</h5>
              <h6>Primer Trimestre</h6>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Muebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2023/1er Trimestre/PUBLICACIÓN DE BIENES MUEBLES PRIMER TRIMESTRE 2023.xls"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Inmuebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2023/1er Trimestre/PUBLICACIÓN DE BIENES INMUEBLES PRIMER TRIMESTRE 2023.xlsx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* SECTION 2022 - Cuarto Trimestre*/}
        <div className="col-md-4 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2022</h5>
              <h6>Cuarto Trimestre</h6>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Muebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2022/4to Trimestre/CATALOGO DE BIENES MUEBLES CUARTO TRIMESTRE 2022.xls"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Inmuebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2022/4to Trimestre/CATALOGO DE BIENES  INM. CUARTO TRIMESTRE 2022..xlsx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* SECTION 2022 - Tercer Trimestre*/}
        <div className="col-md-4 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2022</h5>
              <h6>Tercer Trimestre</h6>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Muebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2022/3er Trimestre/CATALOGO DE BIENES MUEBLES AL 30 DE SEPTIEMBRE 2022 (PUBLICACION).xls"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
              <div className="card_trimestre">
                <p>
                  Catalogo de Bienes Inmuebles
                  <span className="badge bg-secondary bg-green">
                    <a
                      href="/CAASIM/catalogo-bmei/2022/3er Trimestre/CATALOGO DE BIENES INMUEBLES AL 30 DE SEPTIEMBRE 2022 (PUBLICACION) (1).xlsx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      XLSX
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CatalogoBMeI;
