import React from "react";
import Card from "../Card/Card";

const data = [
  {
    document: "Programa",
    year: '2024',
    title: 'Programa Anual de Trabajo CEPCI 2024',
    link: '/CAASIM/comite-de-etica/Programa Anual de Trabajo.pdf'
  },
  {
    document: "Protocolo",
    year: '2024',
    title: 'Protocolo para la Atención de Denuncias ante el CEPCI.',
    link: '/CAASIM/comite-de-etica/Protocolo para la Atención de Denuncias ante el CEPCI.pdf'
  },
  {
    document: "Código",
    year: '2024',
    title: 'Código de Ética',
    link: '/CAASIM/comite-de-etica/Codigo Ética Estado.pdf'
  },
  {
    document: "Código",
    year: '2024',
    title: 'Código de Conducta',
    link: '/CAASIM/comite-de-etica/Codigo de Conducta .pdf'
  },
  {
    document: "Acuerdo",
    year: '2021',
    title: 'Acuerdo que Modifica la Integracion del CEPCI 2021',
    link: '/CAASIM/comite-de-etica/Acuerdo que Modifica la Integracion del CEPCI 2021.pdf'
  },
  {
    document: "Acuerdo",
    year: '2018',
    title: 'Acuerdo de Creación de CEPCI 2018',
    link: '/CAASIM/comite-de-etica/Acuerdo de Creación de CEPCI 2018.pdf'
  },
];

function ComiteDeEtica() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title text-uppercase">Comité de Ética</h3>
        <hr className="hr-gob" />
      </div>
      <div>
        <h4 className="title">Objetivo</h4>
        <p className="mb-4">El Comité de Ética y Prevención de Conflictos de Interés (CEPCI) tiene el objeto de fomentar la integridad y el comportamiento ético de los servidores públicos en el desempeño de sus funciones y que contribuirá a la consolidación de procesos de rendición de cuentas y transparencia, generando una cultura de principios y valores que fortalezcan el compromiso de los servidores públicos, así como para mejorar el servicio público a través de normas que regulen el comportamiento y la conducta de quienes laboran en la Comisión de Agua y Alcantarillado de Sistemas Intermunicipales.</p>
      </div>
      <div className="row">
        {data.map((item, index) => (
          <Card
            key={index}
            document={item.document}
            title={item.title}
            href={item.link}
            year={item.year}
            type={"PDF"}
          />
        ))}
      </div>
    </div>
  );
}

export default ComiteDeEtica;
