// Convocatorias 2024

export const convocatoria001 = [
  {
    nombre: "Convocatoria 01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/CONVOCATORIA 01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria002 = [
  {
    nombre: "Convocatoria 02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/CONVOCATORIA 02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria003 = [
  {
    nombre: "Convocatoria 03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024/CONVOCATORIA 03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n1 = [
  {
    nombre: "Bases N1",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N1/BASES EA-913005999-N1-2024 CARGO ADMINISTRATIVO POR LA PRESTACIÓN DEL SERVICIO DE DISPERSIÓN DE VALES DE DESPENSA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N1",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N1/JUNTA DE ACLARACIONES EA-913005999-N1-2024 CARGO ADMINISTRATIVO POR LA PRESTACIÓN DEL SERVICIO DE DISPERSIÓN DE VALES DE DESPENSA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N1",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N1/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N1-2024 CARGO ADMINISTRATIVO POR LA PRESTACIÓN DEL SERVICIO DE DISPERSIÓN DE VALES DE DESP.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N1",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N1/FALLO EA-913005999-N1-2024 CARGO ADMINISTRATIVO POR LA PRESTACIÓN DEL SERVICIO DE DISPERSIÓN DE VALES DE DESPENSA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n2 = [
  {
    nombre: "Bases N2",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N2/BASES EA-913005999-N2-2024 ALIMENTACION.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N2",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N2/JUNTA DE ACLARACIONES EA-913005999-N2-2024 ALIMENTACIÓN DE PERSONAS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N2",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N2/PRESENTACION Y APERTURA DE PROPOSICIONES  EA-913005999-N2-2024 ALIEMTANCION DE PERSONAS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N2",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N2/FALLO EA-913005999-N2-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n3 = [
  {
    nombre: "Bases N3",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N3/BASES EA-913005999-N3-2024 ARRENDAMIENTO DE MAQUINARIA, OTROS EQUIPOS Y HERRAMIENTAS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N3",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N3/JUNTA DE ACLARACIONES EA-913005999-N3-2024 ARRENDAMIENTO DE MAQUINARIA, OTROS EQUIPOS Y HERRAMIENTAS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N3",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N3/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N3-2024 ARRENDAMIENTO DE MAQUINARIA, OTROS EQUIPOS Y HERRAMIENTAS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N3",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N3/FALLO EA-913005999-N3-2024 ARRENDAMIENTO DE MAQUINARIA, OTROS EQUIPOS Y HERRAMIENTAS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n4 = [
  {
    nombre: "Bases N4",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N4/BASES EA-913005999-N4-2024 SERVICIO DE TRASLADO DE VALORES.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N4",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N4/JUNTA DE ACLARACIONES EA-913005999-N4-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N4",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N4/APERTURA TECNICA Y ECONOMICA EA-913005999-N4-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N4",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N4/FALLO EA-913005999-N4-2024 TRASLADO DE VALORES (2DO. PROCED.).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n5 = [
  {
    nombre: "Bases N5",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N5/BASES EA-913005999-N5-2024 SEGUROS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N5",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N5/JUNTA DE ACLARACIONES EA-913005999-N5-2024 SEGUROS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N5",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N5/APERTURA TECNICA Y ECONOMICA EA-913005999-N5-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N5",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N5/FALLO EA-913005999-N5-2024 SEGUROS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n6 = [
  {
    nombre: "Bases N6",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024/N6/BASES EA-913005999-N6-2024 SERVICIOS DE ACCESO DE INTERNET, REDES Y PROCESAMIENTO DE INFORMACIÓN.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N6",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024/N6/Junta de Aclaraciones EA-913005999-N6-2024 Servicios de Acceso de Internet, Redes y Procesamiento de Información.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N6",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024/N6/Acta de Presentación y Apertura de Proposiciones EA-913005999-N6-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N6",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024/N6/Acta de Fallo EA-913005999-N6-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoriaLOP001 = [
  {
    nombre: "Convocatoria 01-2024 OBRA",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CONVOCATORIA 01-2024 OBRA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop1 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/BASES CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
            // new: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
            // new: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
            // new: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "PLANO PROYECTO FINAL DETALLES CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/PLANO PROYECTO FINAL DETALLES CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
            // new: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "PLANO PROYECTO FINAL PGDA CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/PLANO PROYECTO FINAL PGDA CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
            // new: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "ACUERDO FE DE ERRATAS CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/ACUERDO FE DE ERRATAS CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
            // new: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre:
      "PRESENTACIÓN Y APERTURA DE PROPOSICIONES CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // publicationDate: "2024-22-04",
            // new: "www.google.com",
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/PRESENTACIÓN Y APERTURA DE PROPOSICIONES CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // publicationDate: "2024-22-04",
            // new: "www.google.com",
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/FALLO CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria004 = [
  {
    nombre: "Convocatoria 04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024/CONVOCATORIA 04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria005 = [
  {
    nombre: "Convocatoria 05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/CONVOCATORIA 05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n7 = [
  {
    nombre: "Bases N7",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024/N7/BASES EA-913005999-N7-2024 CAPACTACIÓN.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N7",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024/N7/JUNTA DE ACLARACIONES EA-913005999-N7-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N7",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024/N7/APERTURA TECNICA ECONOMICA EA-913005999-N7-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO N7",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024/N7/FALLO EA-913005999-N7-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n8 = [
  {
    nombre: "Bases N8",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N8/BASES EA-913005999-N8-2024 Combustibles, lubricantes y aditivos como materia prima.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N8",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N8/JUNTA DE ACLARACIONES EA-913005999-N8-2024 COMBUSTIBLES, LUBRICANTES Y ADITIVOS COMO MATERIA PRIMA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N8",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N8/Acta de Presentación y Apertura EA-913005999-N8-2024 Combustibles, Lubricantes y Aditivos como Materia Prima.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO N8",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N8/FALLO EA-913005999-N8-2023 COMBUSTIBLES, LUBRICANTES Y ADITIVOS COMO MATERIA PRIMA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n9 = [
  {
    nombre: "Bases N9",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N9/BASES DE LICITACIÓN EA-913005999-N9-2024 MATERIAL DE LIMPIEZA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N9",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N9/JUNTA DE ACLARACIONES EA-913005999-N9-2024 MATERIAL DE LIMPIEZA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N9",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N9/Acta de Presentación y Apertura EA-913005999-N9-2024 Material de Limpieza.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO DIFERIDO N9",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N9/FALLO DIFERIDO ESA-913005999-N9-2024 MATERIAL DE LIMPIEZA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO N9",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N9/FALLO  EA-913005999-N9-2024 MATERIAL DE LIMPIEZA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n10 = [
  {
    nombre: "Bases N10",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N10/BASES DE LICITACIÓN EA-913005999-N10-2024 SUSTANCIAS QUÍMICAS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N10",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N10/JUNTA DE ACLARACIONES EA-913005999-N10-2024 SUSTANCIAS QUÍMICAS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Continuación Junta de Aclaraciones N10",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N10/CONTINUACIÓN DE JUNTA DE ACLARACIONES EA-913005999-N10-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N10",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N10/Acta de Presentación y Apertura EA-913005999-N10-2024 Sustancias Químicas.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO N10",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N10/Fallo EA-913005999-N10-2024 Sustancias Químicas.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n11 = [
  {
    nombre: "Bases N11",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N11/BASES EA-913005999-N11-2024 GASTOS DE ORDEN SOCIAL.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N11",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N11/JUNTA DE ACLARACIONES EA-913005999-N11-2024 GASTOS DE ORDEN SOCIAL.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N11",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N11/Acta de Presentación y Apertura EA-913005999-N11-2024 Gastos de Orden Social y cultural.PDF",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO N11",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N11/FALLO EA-913005999-N11-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria006 = [
  {
    nombre: "Convocatoria 06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/CONVOCATORIA 06-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n12 = [
  {
    nombre: "Bases N12",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N12/BASES DE LICITACIÓN EA-913005999-N12-2024 COMBUSTIBLES Y LUBRICANTES PARA VEHÍCULOS Y EQUIPOS TERRESTRES.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N12",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N12/JUNTA DE ACLARACIONES EA-913005999-N12-2024.pdf ",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N12",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N12/PRESENTACIÓN Y APERTURA DE PROPODICIONES EA-913005999-N12-2024 COMBUSTIBLES Y LUBRICANTES PARA VEHICULOS Y EQUIPOS TERRESTRES.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N12",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N12/FALLO EA-913005999-N12-2024 COMBUSTIBLES Y LUBRICANTES PARA VEHICULOS Y EQUIPO TERRESTRE.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n13 = [
  {
    nombre: "Bases N13",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N13/BASES DE LICITACIÓN EA-913005999-N13-2024 PRODUCTOS MINERALES NO METÁLICOS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N13",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N13/JUNTA DE ACLARACIONES EA-913005999-N13-2024 PRODUCTOS MINERALES NO METÁLICOS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N13",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N13/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N13-2024 PRODUCTOS MINERALES NO METÁLICOS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N13",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N13/FALLO EA-913005999-N13-2024 PRODUCTOS MINERALES NO METÁLICOS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n14 = [
  {
    nombre: "Bases N14",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N14/BASES DE LICITACIÓN EA-913005999-N14-2024 OTROS MATERIALES Y ARTICULOS DE CONSTRUCCION.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N14",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N14/JUNTA DE ACLARACIONES EA-913005999-N14-2024 OTROS MATERIALES Y ARTICULOS DE CONSTRUCCION Y REPARACIÓN.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N14",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N14/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N14-2024 OTROS MATERIALES Y ARTÍCULOS DE CONSTRUCCIÓN Y REPARACIÓN.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N14",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N14/FALLO EA-913005999-N14-2024 OTROS MATERIALES Y ARTICULOS DE CONSTRUCCIÓN Y REPARACIÓN.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n15 = [
  {
    nombre: "Bases N15",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N15/BASES DE LICITACIÓN EA-913005999-N15-2024 ADQUISICIÓN DE ADQUISICIÓN DE MONTACARGAS DE 3000KG.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N15",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N15/JUNTA DE ACLARACIONES EA-913005999-N15-2024 ADQUISICIÓN DE MONTACARGAS DE 3000KG.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N15",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N15/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N15-2024 ADQUISICIÓN DE MONTACARGAS DE 3000KG.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N15",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N15/FALLO EA-913005999-N15-2024 ADQUISICIÓN DE MONTACARGAS DE 3000KG.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n16 = [
  {
    nombre: "Bases N16",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N16/BASES DE LICITACIÓN EA-913005999-N16-2024 ADQ. DE MEDIDORES.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N16",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N16/JUNTA DE ACLARACIONES EA-913005999-N16-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N16",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N16/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N16-2024 ADQUISICIÓN DE MEDIDORES PARA EL SISTEMA COMERCIAL DE LA CAASIM.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N16",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N16/FALLO EA-913005999-N16-2024 ADQUISICIÓN DE MEDIDORES PARA EL SISTEMA COMERCIAL DE LA CAASIM.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n17 = [
  {
    nombre: "Bases 17",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N17/BASES DE LICITACIÓN EA-913005999-N17-2024 ADQUISICIÓN DE GRÚA TIPO HIAB.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N17",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N17/JUNTA DE ACLARACIONES EA-913005999-N17-2024 Adquisición de Grúa tipo HIAB.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N17",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N17/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N17-2024 ADQUISICIÓN DE GRUA TIPO Tipo HIAB.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N17",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N17/FALLO EA-913005999-N17-2024 ADQUISICIÓN DE GRÚA TIPO HIAB.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n18 = [
  {
    nombre: "Bases N18",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N18/BASES DE LICITACIÓN EA-913005999-N18-2024 ADQUISICIÓN DE DOS CAMIONES CISTERNA .pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N18",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N18/JUNTA DE ACLARACIONES EA-913005999-N18-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N18",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N18/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N18-2024 ADQUISICIÓN DE DOS CAMIONES CISTERNA DE 103 DE CAPACIDAD.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N18",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N18/FALLO EA-913005999-N18-2024 ADQUISICIÓN DE DOS CAMIONES CISTERNA DE 10 M3 DE CAPACIDAD.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n19 = [
  {
    nombre: "Bases N19",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N19/BASES DE LICITACIÓN EA-913005999-N19-2024 ADQUISICIÓN DE EQUIPO SEPTICO HIDRONEUMATICO PARA AGUAS NEGRAS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N19",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N19/JUNTA DE ACLARACIONES EA-913005999-N19-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N19",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N19/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N19-2024 ADQUISICIÓN DE EQUIPO SÉPTICO HIDRONEUMÁTICO PARA AGUAS NEGRAS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N19",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N19/FALLO EA-913005999-N19-2024 ADQUISICIÓN DE EQUIPO SÉPTICO HIDRONEUMÁTICO PARA AGUAS NEGRAS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria007 = [
  {
    nombre: "Convocatoria 07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024/CONVOCATORIA 07-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n20 = [
  {
    nombre: "Bases N20",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024/N20/BASES DE LICITACIÓN EA-913005999-N20-2024 MANTENIMIENTO DE MAQUINARIA Y EQUIPO.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N20",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024/N20/JUNTA DE ACLARACIONES EA-913005999-N20-2024 MANTENIMIENTO DE MAQUINARIA Y EQUIPO.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N20",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024/N20/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N20-2024 MANTENIMIENTO DE MAQUINARIA Y EQUIPO.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo Diferido N20",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024/N20/FALLO DIFERIDO EA-913005999-N20-2024 MANTENIMIENTO DE MAQUINARIA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N20",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024/N20/FALLO EA-913005999-N20-2024 MANTENIMIENTO DE MAQUINARIA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoriaMultiple002 = [
  {
    nombre: "CONVOCATORIA MULTIPLE 02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CONVOCATORIA MULTIPLE 02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop2 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-02-2024/BASES CAASIM-HGO-L.O.P.-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-L.O.P.-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-02-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-02-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-02-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-02-2024001.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura CAASIM-HGO-L.O.P.-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-02-2024/PRESENTACIÓN Y APERTURA DE PROPOSICIONES CAASIM-HGO-L.O.P.-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo CAASIM-HGO-L.O.P.-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-02-2024/FALLO CAASIM-HGO-L.O.P.-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop3 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-03-2024/BASES CAASIM-HGO-L.O.P.-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-L.O.P.-03-2024 ",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-03-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-03-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-03-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-03-2024001.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura CAASIM-HGO-L.O.P.-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-03-2024/PRESENTACIÓN Y APERTURA DE PROPOSICIONES CAASIM-HGO-L.O.P.-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo CAASIM-HGO-L.O.P.-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-03-2024/FALLO CAASIM-HGO-L.O.P.-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop4 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-04-2024/BASES CAASIM-HGO-L.O.P.-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-L.O.P.-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-04-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-04-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-04-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-04-2024001.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura CAASIM-HGO-L.O.P.-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-04-2024/PRESENTACIÓN Y APERTURA DE PROPOSICIONES CAASIM-HGO-L.O.P.-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo CAASIM-HGO-L.O.P.-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-04-2024/FALLO CAASIM-HGO-L.O.P.-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria03Obra = [
  {
    nombre: "CONVOCATORIA 03-2024 OBRA",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CONVOCATORIA 03-2024 OBRA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop5 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CAASIM-HGO-L.O.P.-05-2024/BASES CAASIM-HGO-L.O.P.-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-L.O.P.-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CAASIM-HGO-L.O.P.-05-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CAASIM-HGO-L.O.P.-05-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CAASIM-HGO-L.O.P.-05-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-05-20001.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura CAASIM-HGO-L.O.P.-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CAASIM-HGO-L.O.P.-05-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-L.O.P.-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo CAASIM-HGO-L.O.P.-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CAASIM-HGO-L.O.P.-05-2024/FALLO CAASIM-HGO-L.O.P.-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria008 = [
  {
    nombre: "CONVOCATORIA 08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/CONVOCATORIA 08-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n22 = [
  {
    nombre: "Bases N22",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N22/BASES DE LICITACIÓN EA-913005999-N22-2024 PRODUCTOS MINERALES NO METÁLICOS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N22",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N22/JUNTA DE ACLARACIONES EA-913005999-N22-2024 PRODUCTOS MINERALES NO METÁLICOS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N22",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N22/Presentación y Apertura de Proposiciones N22.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N22",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N22/FALLO N22.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n24 = [
  {
    nombre: "Bases N24",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N24/BASES DE LICITACIÓN EA-913005999-N24-2024 MANTENIMIENTO DE MAQUINARIA Y EQUIPO (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N24",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N24/JUNTA DE ACLARACIONES EA-913005999-N24-2024 MANTENIMIENTO DE MAQUINARIA Y EQUIPO (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N24",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N24/Presentación y Apertura de Proposiciones N24.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N24",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N24/FALLO N24.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n25 = [
  {
    nombre: "Bases N25",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N25/BASES DE LICITACIÓN EA-913005999-N25-2024 MANTENIMIENTO E INSTALACIÓN DE EQUIPO Y HERRAMIENTAS PARA SUMINISTRO DE AGUA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N25",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N25/JUNTA DE ACLARACIONES EA-913005999-N25-2024 MATENIMIENTO E INSTALACIÓN DE EQUIPOSY HERRAMIENTAS PARA SUMINISTRO DE AGUA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N25",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N25/Presentación y Apertura de Proposiciones N25.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N25",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N25/FALLO N25.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria009 = [
  {
    nombre: "CONVOCATORIA 09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/CONVOCATORIA 09-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n21 = [
  {
    nombre: "Bases N21",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N21/BASES DE LICITACIÓN EA-913005999-N21-2024 ADQUISICION DE TRANSFORMADORES PARA SUSTITUCION.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N21",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N21/JUNTA DE ACLARACIONES EA-913005999-N21-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N21",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N21/Presentación y Apertura de Proposiciones N21.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N21",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N21/FALLO EA-913005999-N21-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n26 = [
  {
    nombre: "Bases N26",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N26/BASES DE LICITACIÓN EA-913005999-N26-2024 EQUIPAMIENTO DE LA PTAR MATILDE.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N26",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N26/JUNTA DE ACLARACIONES EA-913005999-N26-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N26",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N26/Presentación y Apertura de Proposiciones N26.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N26",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N26/FALLO EA-913005999-N26-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria010 = [
  {
    nombre: "CONVOCATORIA 10-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 10-2024/CONVOCATORIA 10-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n27 = [
  {
    nombre: "Bases N27",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 10-2024/N27/BASES DE LICITACIÓN EA-913005999-N27-2024 EQUIPAMIENTOELECTROMECÁNICO PARA PLANTAS DE BOMBEO EN LA ZONA DE INFLUENCIA DE LA CAASIM.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N27",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 10-2024/N27/JUNTA DE ACLARACIONES EA-913005999-N27-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N27",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 10-2024/N27/EA-913005999-N27-2024 PRESENTACIÓN Y APERTURA DE PROPOSICIONES EQUIPAMENTO ELECTROMECÁNICO PARA PLANTAS DE BOMBEO EN LA ZONA DE INFLUENCIA DE LA CAASIM.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N27",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 10-2024/N27/FALLO EA-913005999-N27-2024 EQUIPAMENTO ELECTROMECÁNICO PARA PLANTAS DE BOMBEO EN LA ZONA DE INFLUENCIA DE LA CAASIM.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];
//

export const convocatoria04Obra = [
  {
    nombre: "CONVOCATORIA 04-2024 OBRA",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024 OBRA/CONVOCATORIA 04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop6 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024 OBRA/CAASIM-HGO-L.O.P.-06-2024/BASES CAASIM-HGO-L.O.P.-06-2024 (SEGUNDO PROCEDIMIENTO)_.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catálogo Mudo CAASIM-HGO-L.O.P.-06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024 OBRA/CAASIM-HGO-L.O.P.-06-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-06-2024 (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024 OBRA/CAASIM-HGO-L.O.P.-06-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-06-2024 (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024 OBRA/CAASIM-HGO-L.O.P.-06-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P-06-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Bases CAASIM-HGO-L.O.P.-06-2024",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           // excel: "www.google.com",
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024 OBRA/CAASIM-HGO-L.O.P.-06-2024/",
  //           // word: "www.google.com",
  //         },
  //       ],
  //     },
  //   ],
  // },
  //
];

export const convocatoria011 = [
  {
    nombre: "CONVOCATORIA 11-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/CONVOCATORIA 11-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n28 = [
  {
    nombre: "Bases N28",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N28/BASES EA-913005999-N28-2024 CEMENTO Y PRODUCTOS DE CONCRETO.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N28",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N28/JUNTA DE ACLARACIONES EA-913005999-N28-2024 CEMENTO Y PRODUCTOS DE CONCRETO.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N28",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N28/Presentación y Apertura de Proposiciones N28.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N28",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N28/FALLO EA-913005999-N28-2024 CEMENTO Y PRODUCTOS DE CONCRETO.pdf",
          },
        ],
      },
    ],
  },
];

export const n29 = [
  {
    nombre: "Bases N29",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N29/BASES DE LICITACIÓN EA-913005999-N29.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N29",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N29/JUNTA DE ACLARACIONES EA-913005999-N29-2024 SEGUROS.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N29",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N29/ACTA DE APERTURA DE PROPOSICIONES EA-913005999-N29-2024 SEGUROS_001.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N29",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N29/FALLO EA-913005999-N29-2024 SEGUROS.pdf",
          },
        ],
      },
    ],
  },
];

export const n30 = [
  {
    nombre: "Bases N30",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N30/BASES EA-913005999-N30-2024 SERVICIO DE LIMPIEZA Y MANEJO DE DESECHOS.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N30",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N30/JUNTA DE ACLARACIONES EA-913005999-N30-2024 SERVICIO DE LIMPIEZA Y MANEJO DE DESECHOS.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N30",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N30/Presentación y Apertura de Proposiciones N30.pdf",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Fallo N30",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N30/FALLO EA-913005999-N30-2024.pdf",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const convocatoriaMultiple005 = [
  {
    nombre: "CONVOCATORIA MULTIPLE 05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CONVOCATORIA MULTIPLE 05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop7 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-07-2024/BASES CAASIM-HGO-L.O.P.-07-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-07-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-07-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catálogo Mudo CAASIM-HGO-L.O.P.-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-07-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-07-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-07-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-07-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-L.O.P.-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-07-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-L.O.P.-07-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo CAASIM-HGO-L.O.P.-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-07-2024/FALLO CAASIM-HGO-L.O.P.-07-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const lop8 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-08-2024/BASES CAASIM-HGO-L.O.P.-08-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-08-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-08-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catálogo Mudo CAASIM-HGO-L.O.P.-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-08-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-08-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-08-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-08-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-L.O.P.-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-08-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-L.O.P.-08-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo CAASIM-HGO-L.O.P.-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-08-2024/FALLO CAASIM-HGO-L.O.P.-08-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const convocatoria012 = [
  {
    nombre: "CONVOCATORIA 12-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/CONVOCATORIA 12-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n31 = [
  {
    nombre: "Bases N31",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/BASES EA-913005999-N31-2024 FORMAS VALORADAS.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N31",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/JUNTA DE ACLARACIONES EA-913005999-N31-2024 FORMAS VALORADAS.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  
  {
    nombre: "Partida 1 Formato de Acta Circunstanciada",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 1 FORMATO DE ACTA CIRCUNTANCIADA.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 3 Calcomania 3er Trimestre 2024.jpg",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 1 FORMATO DE ACTA CIRCUNTANCIADA.pdf",
            jpg: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 3 CALCOMANIA 3ER TRIMESTRE 2024.jpg",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 5 Etiqueta Muestra 11X10",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            word: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 5 ETIQUETA MUESTRA 11X10.docx",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 6 Formato de Sello Adherible de Suspendido",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 6 FORMATO DE  SELLO ADHERIBLE DE SUSPENDIDO.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 7 Formato de Contrato de Prestacion de Servicios",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 7 FORMATO DE CONTRATO DE PRESTACIÓN DE SERVICIOS.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 9 Calcomania 4to Trimestre 2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            jpg: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 9 CALCOMANIA 4TO TRIMESTRE 2024.jpg",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 10 Formato de Informe de Instalacion o Sustitucion",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 10 fORMATO DE INFORME DE INSTALACIÓN O SUSTITUCIÓN.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 11 Formato de Solicitud de Cambio al Padron de Usuarios",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 11 FORMATO DE SOLICITUD DE CAMBIO AL PADRON DE USUARIOS.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 13 Calcomania 1er Trimestre 2025",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            jpg: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 13 CALCOMANIA 1ER TRIMESTRE 2025.jpg",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 14 Anverso Formato de Hoja de Recibo de Agua Potable",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 14 ANVERSO FORMATO DE HOJA DE RECIBO DE AGUA POTABLE.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 14 Reverso Formato de Hoja de Recibo de Agua Potable",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 14 REVERSO FORMATO DE HOJA DE RECIBO DE AGUA POTABLE.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre:
      "Partido 15 Formato de Informe de Supervision de Instalacion y Verificacion",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 15 FORMATO DE INFORME DE SUPERVISIÓN Y VERIFICACIÓN.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 16 Calcomania 2do Trimestre 2025",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            jpg: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 16 CALCOMANIA 2DO TRIMESTRE 2025.jpg",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 17 Formato de Reporte de Usuario",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            excel:
              "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 17 FORMATO REPORTE DE USUARIO.xls",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 18 Formato de Verificacion Pipas Agua Potable",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            excel:
              "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 18 FORMATO VERIFICACIÓN PIPAS AGUA POTABLE.xls",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 19 Formato Prefactura",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            excel:
              "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 19 FORMATO PREFACTURA.xls",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partido 20 Etiqueta Muestra 7X8",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 20  ETIQUETA MUESTRA 7X8.docx",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Acta de Apertura N31",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/ACTA DE APERTURA  EA-913005999-N31-2024 FORMAS VALORADAS.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N31",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/FALLO EA-913005999-N31-2024 FORMAS VALORADAS.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  
];

export const convocatoria06Obra = [
  {
    nombre: "CONVOCATORIA 06-2024 OBRA",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024 OBRA/CONVOCATORIA 06-2024 OBRA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop9 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024 OBRA/CAASIM-HGO-L.O.P.-09-2024/BASES CAASIM-HGO-L.O.P.-09-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024 OBRA/CAASIM-HGO-L.O.P.-09-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-09-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catálogo Mudo CAASIM-HGO-L.O.P.-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024//CONVOCATORIA 06-2024 OBRA/CAASIM-HGO-L.O.P.-09-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-09-2024.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024//CONVOCATORIA 06-2024 OBRA/CAASIM-HGO-L.O.P.-09-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-09-2024.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura CAASIM-HGO-L.O.P.-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024//CONVOCATORIA 06-2024 OBRA/CAASIM-HGO-L.O.P.-09-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-L.O.P.-09-2024.pdf",
            new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo CAASIM-HGO-L.O.P.-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024//CONVOCATORIA 06-2024 OBRA/CAASIM-HGO-L.O.P.-09-2024/FALLO CAASIM-HGO-L.O.P.-09-2024.pdf",
            new: true,
          },
        ],
      },
    ],
  },
];

export const convocatoria013 = [
  {
    nombre: "CONVOCATORIA 13-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 13-2024/CONVOCATORIA 13-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n32 = [
  {
    nombre: "Bases N32",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 13-2024/N32/BASES EA-913005999-N32-2024 CEMENTO Y PRODUCTOS DE CONCRETO (SEGUNDO PROCEDIMIENTO).pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N32",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 13-2024/N32/JUNTA DE ACLARACIONES EA-913005999-N32-2024 CEMENTO Y PRODUCTOS DE CONCRETO (SEGUNDO PROCEDIMIENTO).pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N32",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 13-2024/N32/Presentación y Apertura de Proposiciones N32.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N32",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 13-2024/N32/Fallo N32.pdf",
          },
        ],
      },
    ],
  },
];

export const n33 = [
  {
    nombre: "Bases N33",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 13-2024/N33/BASES EA-913005999-N33-2024 SERVICIO DE LIMPIEZA Y MANEJO DE DESECHOS SEGUNDO PROCEDIMIENTO.docx.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N33",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 13-2024/N33/JUNTA DE ACLARACIONES EA-913005999-N33-2024 SERVICIO DE LIMPIEZA Y MANEJO DE DESECHOS 2do Proced.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N33",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 13-2024/N33/ACTA APERTURA EA-913005999-N33-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N33",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 13-2024/N33/FALLO EA-913005999-N33-2023.pdf"
          },
        ],
      },
    ],
  }
];

export const convocatoria014 = [
  {
    nombre: "CONVOCATORIA 14-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/CONVOCATORIA 14-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n34 = [
  {
    nombre: "Bases N34",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N34/BASES DE LICITACIÓN EA-913005999-N34-2024 MERCANCIAS ADQUIRIDAS PARA SU COMERCIALIZACION.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N34",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N34/JUNTA DE ACLARACIONES  EA-913005999-N34-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N34",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N34/APERTURA TECNICA Y ECONOMICA EA-913005999-N34-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N34",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N34/FALLO EA-913005999-N34-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n35 = [
  {
    nombre: "Bases N35",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N35/BASES DE LICITACIÓN EA-913005999-N35-2024 ARRENDAMIENTO DE VEHÍCULOS Y EQUIPO DE TRANSPORTE.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N35",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N35/JUNTA DE ACLARACIONES EA-913005999-N35-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N35",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N35/APERTURA TECNICA Y ECONOMICA EA-913005999-N35-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N35",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N35/FALLO EA-913005999-N35-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n36 = [
  {
    nombre: "Bases N36",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N36/BASES DE LICITACIÓN EA-913005999-N36-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N36",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N36/JUNTA DE ACLARACIONES EA-913005999-N36-2024.pdf.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N36",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N36/APERTURA DE PROPOSICIONES EA-913005999-N36-2024 SERVICIOS INT.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N36",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N36/FALLO  EA-913005999-N36-2024 SERVICIOS INTEGRALES.pdf",
          },
        ],
      },
    ],
  },
];

export const n37 = [
  {
    nombre: "Bases N37",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N37/BASES DE LICITACIÓN EA-913005999-N37-2024 ARRENDAMIENTO DE MAQUINARIA, OTROS EQUIPOS Y HERRAMIENTAS.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N37",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N37/Junta de Aclaraciones N37.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N37",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 14-2024/N37/Presentación y Apertura de Proposiciones N37.pdf",
          },
        ],
      },
    ],
  }
];

export const convocatoria07Obra = [
  {
    nombre: "CONVOCATORIA 07-2024 OBRA",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CONVOCATORIA 07-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const lop10 = [
  {
    nombre: "Bases",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-10-2024/BASES CAASIM-HGO-L.O.P.-10-2024_.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-10-2024/PLANO DE LOCALIZACION CAASIM-HGO-L.O.P.-10-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catálogo Mudo",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-10-2024/CAT MUDO CAASIM-HGO-L.O.P.-10-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-10-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-L.O.P.-10-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-10-2024/PRESENTACION Y APERTURA CAASIM-HGO-L.O.P.-10-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Fallo
  {
    nombre: "Fallo",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-10-2024/FALLO CAASIM-HGO-L.O.P.-10-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const lop11 = [
  {
    nombre: "Bases",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-11-2024/BASES CAASIM-HGO-L.O.P.-11-2024_.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-11-2024/PLANO DE LOCALIZACION CAASIM-HGO-L.O.P.-11-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catálogo Mudo",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-11-2024/CAT MUDO CAASIM-HGO-L.O.P.-11-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-11-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-L.O.P.-11-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Presentación y Apertura de Proposiciones
  {
    nombre: "Presentación y Apertura de Proposiciones",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-11-2024/PRESENTACION Y APERTURA CAASIM-HGO-L.O.P.-11-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Fallo
  {
    nombre: "Fallo",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-11-2024/FALLO CAASIM-HGO-L.O.P.-11-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const lop12 = [
  {
    nombre: "Bases",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-12-2024/BASES CAASIM-HGO-L.O.P.-12-2024..pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-12-2024/PLANO DE LOCALIZACION CAASIM-HGO-L.O.P.-12-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catálogo Mudo",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-12-2024/CAT MUDO CAASIM-HGO-L.O.P.-12-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-12-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-L.O.P.-12-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Presentación y Apertura de Proposiciones
  {
    nombre: "Presentación y Apertura de Proposiciones",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-12-2024/PRESENTACION Y APERTURA CAASIM-HGO-L.O.P.-12-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const lop13 = [
  {
    nombre: "Bases",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-13-2024/BASES CAASIM-HGO-L.O.P.-13-2024..pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-13-2024/PLANO DE LOCALIZACION CAASIM-HGO-L.O.P.-13-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catálogo Mudo",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-13-2024/CAT MUDO CAASIM-HGO-L.O.P.-13-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-13-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-L.O.P.-13-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Presentación y Apertura de Proposiciones
  {
    nombre: "Presentación y Apertura de Proposiciones",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-13-2024/PRESENTACION Y APERTURA CAASIM-HGO-L.O.P.-13-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Fallo
  {
    nombre: "Fallo",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024 OBRA/CAASIM-HGO-L.O.P.-13-2024/FALLO CAASIM-HGO-L.O.P.-13-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const convocatoria015 = [
  {
    nombre: "CONVOCATORIA 15-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 15-2024/CONVOCATORIA 15-2024.pdf",
          },
        ],
      },
    ],
  },
];

// Create the data for the N36, N38, N40
export const n38 = [
  {
    nombre: "Bases N38",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 15-2024/N38/BASES EA-913005999-N38-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N38",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 15-2024/N38/Junta de Aclaraciones EA-913005999-N38-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N38",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 15-2024/N38/ACTA DE APERTURA TECNICA Y ECONOMICA EA-913005999-N38-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N38",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 15-2024/N38/FALLO EA-913005999-N38-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n39 = [
  {
    nombre: "Bases N39",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 15-2024/N39/BASES DE LICITACIÓN EA-913005999-N39-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N39",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 15-2024/N39/Junta de Aclaraciones EA-913005999-N39-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N39",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 15-2024/N39/ACTA DE APERTURA TECNICA Y ECONOMICA EA-913005999-N39-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N39",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 15-2024/N39/FALLO EA-913005999-N39-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n40 = [
  {
    nombre: "Bases N40",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 15-2024/N40/BASES DE LICITACIÓN EA-913005999-N40-2024 ARRENDAMIENTO DE MAQUINARIA, OTROS EQUIPOS Y HERRAMIENTAS (SEGUNDO).pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N40",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 15-2024/N40/JUNTA DE ACLARACIONES EA-913005999-N40-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N40",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 15-2024/N40/ACTA DE APERTURA TÉCNICA Y ECONÓMICA EA-913005999-N40-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N40",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 15-2024/N40/FALLO EA-913005999-N40-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const convocatoria016 = [
  {
    nombre: "CONVOCATORIA 16-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 16-2024/CONVOCATORIA 16-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n41 = [
  {
    nombre: "Bases N41",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 16-2024/N41/BASES DE LICITACIÓN EA-913005999-N41-2024 TELEMETRÍA.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N41",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 16-2024/N41/JUNTA DE ACLARACIONES EA-913005999-N41-2024 TELEMETRÍA.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N41",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 16-2024/N41/APERTURA TECNICA Y ECONOMICA EA-913005999-N41-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N41",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 16-2024/N41/FALLO EA-913005999-N41-2024 TELEMETRIA.pdf",
          },
        ],
      },
    ],
  },
];

export const n42 = [
  {
    nombre: "Bases N42",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 16-2024/N42/BASES DE LICITACIÓN EA-913005999-N42-2024 CATASTROpdf.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N42",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 16-2024/N42/JUNTA DE ACLARACIONES EA-913005999-N42-2024 SERVICIO DE CATASTRO.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N42",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 16-2024/N42/APERTURA TECNICA Y ECONOMICA EA-913005999-N42-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N42",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 16-2024/N42/FALLO EA-913005999-N42-2024 SERVICIO DE CATASTRO.pdf",
          },
        ],
      },
    ],
  },
];

export const convocatoria017 = [
  {
    nombre: "CONVOCATORIA 17-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 17-2024/CONVOCATORIA 17-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n43 = [
  {
    nombre: "Bases N43",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 17-2024/N43/BASES EA-913005999-N43-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Primera Junta de Aclaraciones N43",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 17-2024/N43/PRIMERA JUNTA DE ACLARACIONES EA-913005999-N43-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Segunda Junta de Aclaraciones N43",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 17-2024/N43/SEGUNDA JUNTA DE ACLARACIONES EA-913005999-N43-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N43",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 17-2024/N43/APERTURA TECNICA Y ECONOMICA EA-913005999-N43-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N43",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 17-2024/N43/FALLO EA-913005999-N43-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const convocatoria08Obra = [
  {
    nombre: "CONVOCATORIA 08-2024 OBRA",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024 OBRA/CONVOCATORIA 08-2024 OBRA.pdf",
          },
        ],
      },
    ],
  },
];

export const lop14 = [
  {
    nombre: "Bases",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024 OBRA/CAASIM-HGO-L.O.P.-14-2024/BASES CAASIM-HGO-L.O.P.-14-2024.pdf",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Plano de Localización",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024 OBRA/CAASIM-HGO-L.O.P.-14-2024/PLANO DE LOCALIZACION CAASIM-HGO-L.O.P.-14-2024.pdf",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    nombre: "Catálogo Mudo",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024 OBRA/CAASIM-HGO-L.O.P.-14-2024/CATALOGO CAASIM-HGO-L.O.P.-14-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024 OBRA/CAASIM-HGO-L.O.P.-14-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-14-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for Presentación y Apertura de Proposiciones
  {
    nombre: "Presentación y Apertura de Proposiciones",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024 OBRA/CAASIM-HGO-L.O.P.-14-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-L.O.P.-14-2024.pdf",
          },
        ],
      },
    ],
  },
  // Create for fallo
  {
    nombre: "Fallo",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024 OBRA/CAASIM-HGO-L.O.P.-14-2024/FALLO CAASIM-HGO-L.O.P.-14-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const convocatoria018 = [
  {
    nombre: "CONVOCATORIA 18-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/CONVOCATORIA 18-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n44 = [
  {
    nombre: "Bases N44",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/N44/BASES EA-913005999-N44-2024 CONSERVACION Y MANTENIMIENTO MENOR DE INMUEBLES.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N44",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/N44/JUNTA DE ACLARACIONES EA-913005999-N44-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N44",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/N44/APERTURA TECNICA Y ECONOMICA EA-913005999-N44-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N44",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/N44/FALLO EA-913005999-N44-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n45 = [
  {
    nombre: "Bases N45",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/N45/BASES DE LICITACIÓN EA-913005999-N45-2024 ESTUDIOS E INVESTIGACIONES.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N45",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/N45/JUNTA DE ACLARACIONES EA-913005999-N45-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N45",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/N45/APERTURA TECNICA Y ECONOMICA EA-913005999-N45-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo Diferido N45",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/N45/FALLO DIFERIDO EA-913005999-N45-2024 ESTUDIOS E INVESTIGACIONES.pdf ",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N45",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/N45/FALLO EA-913005999-N45-2024.pdf",
          },
        ],
      },
    ],
  }
];

export const n46 = [
  {
    nombre: "Bases N46",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/N46/BASES EA-913005999-N46-2024 SERVICIOS DE INFORMATICA.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N46",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/N46/JUNTA DE ACLARACIONES EA-913005999-N46-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N46",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/N46/APERTURA TECNICA Y ECONOMICA EA-913005999-N46-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N46",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 18-2024/N46/FALLO EA-913005999-N46-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const convocatoria019 = [
  {
    nombre: "CONVOCATORIA 19-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 19-2024/CONVOCATORIA 19-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n47 = [
  {
    nombre: "Bases N47",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 19-2024/N47/BASES EA-913005999-N47-2024 EQUIPOS Y APARATOS.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N47",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 19-2024/N47/JUNTA DE ACLARACIONES EA-913005999-N47-2024 EQUIPOS Y APARATOS DE COMUNICACIÓN Y TELECOMUNICACIÓN.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N47",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 19-2024/N47/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N47-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N47",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 19-2024/N47/FALLO EA-913005999-N47-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const convocatoria020 = [
  {
    nombre: "CONVOCATORIA 20-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/CONVOCATORIA 20-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n48 = [
  {
    nombre: "Bases N48",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N48/BASES EA-913005999-N48-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N48",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N48/JUNTA DE ACLARACIONES EA-913005999-N48-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N48",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N48/APERTURA TECNICA Y ECONOMICA EA-913005999-N48-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N48",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N48/FALLO EA-913005999-N48-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n49 = [
  {
    nombre: "Bases N49",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N49/BASES EA-913005999-N49-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N49",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N49/JUNTA DE ACLARACIONES EA-913005999-N49-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N49",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N49/APERTURA TECNICA Y ECONOMICA EA-913005999-N49-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N49",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N49/FALLO EA-913005999-N49-2024.pdf",
          },
        ],
      },
    ],
  },
]

export const n50 = [
  {
    nombre: "Bases N50",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N50/BASES EA-913005999-N50-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N50",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N50/JUNTA DE ACLARACIONES EA-913005999-N50-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N50",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N50/APERTURA TECNICA Y ECONOMICA EA-913005999-N50-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N50",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N50/FALLO EA-913005999-N50-2024.pdf",
          },
        ],
      },
    ],
  },
]

export const n51 = [
  {
    nombre: "Bases N51",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N51/BASES EA-913005999-N51-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N51",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N51/JUNTA DE ACLARACIONES EA-913005999-N51-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N51",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N51/APERTURA TECNICA Y ECONOMICA EA-913005999-N51-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N51",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 20-2024/N51/FALLO EA-913005999-N51-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const convocatoria021 = [
  {
    nombre: "CONVOCATORIA 21-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/CONVOCATORIA 21-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n52 = [
  {
    nombre: "Bases N52",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N52/BASES EA-913005999-N52-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N52",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N52/JUNTA DE ACLARACIONES EA-913005999-N52-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N52",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N52/APERTURA TECNICA Y ECONOMICA EA-91300999-N52-2024.pdf",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Fallo N52",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N52/FALLO EA-913005999-N52-2024.pdf",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

// create const for 53 to 60

export const n53 = [
  {
    nombre: "Bases N53",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N53/BASES EA-913005999-N53-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N53",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N53/JUNTA DE ACLARACIONES EA-913005999-N53-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N53",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N53/APERTURA TECNICA Y ECONOMICA EA-91300999-N53-2024.pdf",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Fallo N53",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N53/FALLO EA-913005999-N53-2024.pdf",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const n54 = [
  {
    nombre: "Bases N54",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N54/BASES EA-913005999-N54-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N54",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N54/JUNTA DE ACLARACIONES EA-913005999-N54-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N54",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N54/APERTURA TECNICA Y ECONOMICA EA-91300999-N54-2024.pdf",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Fallo N54",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N54/FALLO EA-913005999-N54-2024.pdf",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const n55 = [
  {
    nombre: "Bases N55",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N55/BASES EA-913005999-N55-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N55",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N55/JUNTA DE ACLARACIONES EA-913005999-N55-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N55",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N55/APERTURA TECNICA Y ECONOMICA EA-91300999-N55-2024.pdf",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Fallo N55",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N55/FALLO EA-913005999-N55-2024.pdf",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const n56 = [
  {
    nombre: "Bases N56",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N56/BASES EA-913005999-N56-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N56",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N56/JUNTA DE ACLARACIONES EA-913005999-N56-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N56",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N56/APERTURA TECNICA Y ECONOMICA EA-91300999-N56-2024.pdf",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Fallo N56",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N56/FALLO EA-913005999-N56-2024.pdf",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const n57 = [
  {
    nombre: "Bases N57",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N57/BASES EA-913005999-N57-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N57",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N57/JUNTA DE ACLARACIONES EA-913005999-N57-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N57",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N57/APERTURA TECNICA Y ECONOMICA EA-91300999-N57-2024.pdf",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Fallo N57",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N57/FALLO EA-913005999-N57-2024.pdf",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const n58 = [
  {
    nombre: "Bases N58",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N58/BASES EA-913005999-N58-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N58",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N58/JUNTA DE ACLARACIONES EA-91335999-N58-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones Reanudación N58",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N58/JUNTA DE ACLARACIONES REANUDACIÓN EA-91335999-N58-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N58",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N58/APERTURA TECNICA Y ECONOMICA EA-91300999-N58-2024.pdf",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Fallo N58",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N58/FALLO EA-913005999-N58-2024.pdf",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const n59 = [
  {
    nombre: "Bases N59",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N59/BASES EA-913005999-N59-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N59",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N59/JUNTA DE ACLARACIONES EA-913005999-N59-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N59",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N59/APERTURA TECNICA Y ECONOMICA EA-91300999-N59-2024.pdf",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Fallo N59",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N59/FALLO EA-913005999-N59-2024.pdf",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const n60 = [
  {
    nombre: "Bases N60",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N60/BASES EA-913005999-N60-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N60",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N60/JUNTA DE ACLARACIONES EA-913005999-N60-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N60",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N60/APERTURA TECNICA Y ECONOMICA EA-91300999-N60-2024.pdf",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Fallo N60",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 21-2024/N60/FALLO EA-913005999-N60-2024.pdf",
  //         },
  //       ],
  //     },
  //   ],
  // },
];