export const rubros = [
  {
    nombre: "01 Normatividad",
    icono: "Normatividad.png",
    formatos: [
      {
        nombre: "Marco Normativo",
        xlsx: "f01",
        // urlXlsx: "",
        html: "f01",
        // urlHTML: "",
      },
    ],
    // "url" : "https://www.google.com"
  },
  {
    nombre: "02 Estructura",
    icono: "Estructura.png",
    formatos: [
      {
        nombre: "Estructura Orgánica",
        xlsx: "f02a",
        html: "f02a",
      },
      {
        nombre: "Estructura Orgánica - Organigrama",
        xlsx: "f02b",
        html: "f02b",
      },
    ],
  },
  {
    nombre: "03 Facultades",
    icono: "Facultades.png",
    formatos: [
      {
        nombre: "Facultades",
        xlsx: "f03",
        html: "f03",
      },
    ],
  },
  {
    nombre: "04 Programas Operativos Anuales",
    icono: "ProgramasOperativosAnuales.png",
    formatos: [
      {
        nombre: "Metas y Objetivos",
        xlsx: "f4",
        html: "f4",
      },
    ],
  },
  {
    nombre: "05 Indicadores de Interés",
    icono: "IndicadoresInteres.png",
    formatos: [
      {
        nombre: "Indicadores de Interés",
        xlsx: "f05",
        html: "f05",
      },
    ],
  },
  {
    nombre: "06 Matriz de Indicadores",
    icono: "MatrizIndicadores.png",
    formatos: [
      {
        nombre: "Matriz de Indicadores",
        xlsx: "f6",
        html: "f6",
      },
    ],
  },
  {
    nombre: "07 Directorio",
    icono: "Directorio.png",
    formatos: [
      {
        nombre: "Directorio",
        xlsx: "f7",
        html: "f7",
      },
    ],
  },
  {
    nombre: "08 Sueldos",
    icono: "Sueldos.png",
    formatos: [
      {
        nombre: "Remuneraciones brutas y netas de todas las personas servidoras públicas de base y de confianza",
        xlsx: "f8",
        html: "f8",
      },
      {
        nombre: "Tabulador de Sueldos y Salarios",
        xlsx: "f8b",
        html: "f8b",
      },
      // {
      //   nombre: "Remuneraciones",
      //   xlsx: "f8",
      //   html: "f8",
      // },
    ],
  },
  {
    nombre: "09 Viáticos",
    icono: "Viaticos.png",
    formatos: [
      {
        nombre: "Viáticos",
        xlsx: "f9",
        html: "f9",
      },
    ],
  },
  {
    nombre: "10 Plazas",
    icono: "Plazas.png",
    formatos: [
      {
        nombre: "Total de Personal de Base y Confianza",
        xlsx: "f10a",
        html: "f10a",
      },
      {
        nombre: "Total de las Plazas de Base y Confianza",
        xlsx: "f10b",
        html: "f10b",
      },
    ],
  },
  {
    nombre: "11 Honorarios",
    icono: "Honorarios.png",
    formatos: [
      {
        nombre: "Personal de Honorarios",
        xlsx: "f11",
        html: "f11",
      },
    ],
  },
  {
    nombre: "12 Declaraciones Públicas",
    icono: "DeclaracionesPublicas.png",
    formatos: [
      {
        nombre: "Declaraciones Patrimoniales",
        xlsx: "f12",
        html: "f12",
      },
    ],
    url: "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/12.%20Declaraciones%20P%C3%BAblicas",
  },
  {
    nombre: "13 Datos de Contacto de la Unidad",
    icono: "DatosContactoUnidad.png",
    formatos: [
      {
        nombre: "Domicilio de la Unidad de Transparencia",
        xlsx: "f13",
        html: "f13",
      },
    ],
    url: "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/13.%20Datos%20de%20Contacto%20de%20la%20Unidad",
  },
  {
    nombre: "14 Convocatorias",
    icono: "Convocatorias.png",
    formatos: [
      {
        nombre: "Convocatorias a Concursos para Cargos Públicos",
        xlsx: "f14",
        html: "f14",
      },
    ],
  },
  {
    nombre: "15 Programas Sociales",
    icono: "ProgramasSociales.png",
    formatos: [
      {
        nombre: "Programas de Subsidio, Estimulo y Apoyo",
        xlsx: "f15a",
        html: "f15a",
      },
      {
        nombre: "Padrón de beneficiarios",
        xlsx: "f15b",
        html: "f15b",
      },
    ],
  },
  {
    nombre: "16 Relaciones Laborales",
    icono: "RelacionesLaborales.png",
    formatos: [
      {
        nombre: "Condiciones Generales de Trabajo",
        xlsx: "f16a",
        html: "f16a",
      },
      {
        nombre: "Recursos Públicos",
        xlsx: "f16b",
        html: "f16b",
      },
    ],
  },
  {
    nombre: "17 Información Curricular",
    icono: "InformacionCurricular.png",
    formatos: [
      {
        nombre: "Información Curricular",
        xlsx: "f17",
        html: "f17",
      },
    ],
  },
  {
    nombre: "18 Sanciones Administrativas",
    icono: "SancionesAdministrativas.png",
    formatos: [
      {
        nombre: "Servidores Públicos Sancionados",
        xlsx: "f18",
        html: "f18",
      },
    ],
    url: "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/18.%20Sanciones%20Administrativas",
  },
  {
    nombre: "19 Servicios",
    icono: "Servicios.png",
    formatos: [
      {
        nombre: "Servicios",
        xlsx: "f19",
        html: "f19",
      },
    ],
  },
  {
    nombre: "20 Trámites",
    icono: "Tramites.png",
    formatos: [
      {
        nombre: "Trámites",
        xlsx: "f20",
        html: "f20",
      },
    ],
  },
  {
    nombre: "21 Presupuesto e Informes Trimestrales",
    icono: "PresupuestoInformesTrimestrales.png",
    formatos: [
      {
        nombre: "Presupuesto Anual",
        xlsx: "f21a",
        html: "f21a",
      },
      {
        nombre: "Gasto Trimestral",
        xlsx: "f21b",
        html: "f21b",
      },
      {
        nombre: "Cuenta Pública",
        xlsx: "f21c",
        html: "f21c",
      },
    ],
  },
  {
    nombre: "22 Deuda Pública",
    icono: "DeudaPublica.png",
    formatos: [
      {
        nombre: "Deuda Pública",
        xlsx: "f22",
        html: "f22",
      },
    ],
    url: "https://hacienda.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/22.Deuda%20P%C3%BAblica",
  },
  {
    nombre: "23 Comunicación Social y Publicidad",
    icono: "ComunicacionSocialPublicidad.png",
    formatos: [
      {
        nombre: "Programa Anual de Comunicación Social",
        xlsx: "f23a",
        html: "f23a",
      },
      {
        nombre: "Erogación de Recursos de Comunicación Social",
        xlsx: "f23b",
        html: "f23b",
      },
      {
        nombre: "Tiempos Oficiales",
        xlsx: "f23c",
        html: "f23c",
        urlXlsx:
          "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/RadioTVH/dir1/FC/a69_f23_c_RTH.xlsx",
        urlHTML:
          "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/RadioTVH/dir1/FC/a69_f23_c_RTH.htm",
      },
      // {
      //   nombre: "Información Relacionada a Tiempos Oficiales",
      //   xlsx: "f23_d",
      //   html: "f23_d",
      //   urlXlsx:
      //     "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/RadioTVH/dir1/2023/Transparencia/xlsx/a69_f23_d_RTVH.xlsx",
      //   urlHTML:
      //     "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/RadioTVH/dir1/2023/Transparencia/htm/a69_f23_d_RTVH.htm",
          
      // },
    ],
  },
  {
    nombre: "24 Resultados de Auditorías",
    icono: "ResultadosAuditorias.png",
    formatos: [
      {
        nombre: "Auditorías",
        xlsx: "f24",
        html: "f24",
      },
    ],
    url: "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/24.%20Resultados%20de%20Auditor%C3%ADas",
  },
  {
    nombre: "25 Dictámines de Estados Financiero",
    icono: "DictamenesFinancieros.png",
    formatos: [
      {
        nombre: "Estados Financieros",
        xlsx: "f25",
        html: "f25",
      },
    ],
  },
  {
    nombre: "26 Asignación de Recursos Públicos a Personas",
    icono: "AsignacionRecursosPublicosPersonas.png",
    formatos: [
      {
        nombre: "Personas a Quienes se asignó Recursos Públcios",
        xlsx: "f26",
        html: "f26",
      },
    ],
  },
  {
    nombre: "27 Concesiones",
    icono: "Concesiones.png",
    formatos: [
      {
        nombre:
          "Concesiones, Contratos, Convenios, Permisos, Licencias y Autorizaciones",
        xlsx: "f27",
        html: "f27",
      },
    ],
  },
  {
    nombre: "28 Licitaciones Públicas",
    icono: "LicitacionesPublicas.png",
    formatos: [
      // {
      //   nombre:
      //     "Procedimientos de Licitación Pública e Invitación a Cuando Menos Tres Personas",
      //   xlsx: "f28a",
      //   html: "f28a",
      // },
      // {
      //   nombre: "Procedimientos de Adjudicación Directa",
      //   xlsx: "f28b",
      //   html: "f28b",
      // },
      {
        nombre: "Resultados de procedimientos de adjudicación directa, licitación pública e invitación restringida",
        xlsx: "f28",
        html: "f28",
      },
    ],
  },
  {
    nombre: "29 Informes",
    icono: "Informes.png",
    formatos: [
      {
        nombre: "Informes",
        xlsx: "f29",
        html: "f29",
      },
    ],
  },
  {
    nombre: "30 Estadísticas Generadas",
    icono: "EstadisticasGenerales.png",
    formatos: [
      {
        nombre: "Estadísticas",
        xlsx: "f30",
        html: "f30",
      },
    ],
  },
  {
    nombre: "31 Informe de Avances y Documentos Financiero",
    icono: "InformeAvancesDocumentosFinancieros.png",
    formatos: [
      {
        nombre: "Informe de Estados Financieros",
        xlsx: "f31a",
        html: "f31a",
      },
      {
        nombre: "Informe Contable, Presupuestal y Programático",
        xlsx: "f31b",
        html: "f31b",
      },
    ],
  },
  {
    nombre: "32 Padrón de Proveedores y Contratistas",
    icono: "PadronProveedoresContratistas.png",
    formatos: [
      {
        nombre: "Padrón de Proveedores y Contratistas",
        xlsx: "f32",
        html: "f32",
      },
    ],
    url: "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/32.%20Padr%C3%B3n%20de%20Proveedores%20y%20Contratistas",
  },
  {
    nombre: "33 Convenios con Sectores Social y Privado",
    icono: "ConveniosSectoresSocialPrivado.png",
    formatos: [
      {
        nombre: "Convenios de Coordinación y Concertación",
        xlsx: "f33",
        html: "f33",
      },
    ],
  },
  {
    nombre: "34 Inventario de Bienes",
    icono: "InventarioBienes.png",
    formatos: [
      {
        nombre: "Inventario de Bienes Muebles",
        xlsx: "f34a",
        html: "f34a",
      },
      {
        nombre: "Alta de Bienes Muebles",
        xlsx: "f34b",
        html: "f34b",
      },
      {
        nombre: "Bajas de Bienes Muebles",
        xlsx: "f34c",
        html: "f34c",
      },
      {
        nombre: "Inventario de Bienes Inmuebles",
        xlsx: "f34d",
        html: "f34d",
      },
      {
        nombre: "Alta de Bienes Inmuebles",
        xlsx: "f34e",
        html: "f34e",
      },
      {
        nombre: "Bajas de Bienes Inmuebles",
        xlsx: "f34f",
        html: "f34f",
      },
      {
        nombre: "Donaciones de Bienes Muebles e Inmuebles",
        xlsx: "f34g",
        html: "f34g",
      },
    ],
  },
  {
    nombre: "35 Recomendaciones y Atención en Materia de Derechos Humanos",
    icono: "RecomendacionesAtenciónMateriaDerechosHumanos.png",
    formatos: [
      {
        nombre: "Recomendaciones de Derechos Humanos Emitidas por la CNDH",
        xlsx: "f35a",
        html: "f35a",
      },
      {
        nombre: "Recomendaciones de Derechos Humanos Casos Especiales",
        xlsx: "f35b",
        html: "f35b",
      },
      {
        nombre: "Las Recomendaciones de Derechos Humanos Org. Int. CNDH",
        xlsx: "f35c",
        html: "f35c",
      },
    ],
  },
  {
    nombre: "36 Resoluciones y Laudos de Juicios",
    icono: "ResolucionesLaudosJuicios.png",
    formatos: [
      {
        nombre: "Resoluciones y Laudos ",
        xlsx: "f36",
        html: "f36",
      },
    ],
  },
  {
    nombre: "37 Mecanismo de Participación Ciudadana",
    icono: "MecanismosParticipacionCiudadana.png",
    formatos: [
      {
        nombre: "Mecanismos de Participación Ciudadana",
        xlsx: "f37a",
        html: "f37a",
      },
      {
        nombre: "Resultados de los Mecanismos de Participación Ciudadana",
        xlsx: "f37b",
        html: "f37b",
      },
    ],
  },
  {
    nombre: "38 Programas Ofrecidos",
    icono: "ProgramasOfrecidos.png",
    // formatos: [
    //   {
    //     nombre: "Programas que Ofrecen",
    //     xlsx: "f38a",
    //     html: "f38a",
    //   },
    //   {
    //     nombre: "Tramites a los Programas que Ofrecen",
    //     xlsx: "f38b",
    //     html: "f38b",
    //   },
    // ],
    "url" : "https://s-salud.hidalgo.gob.mx/Transparencia/RubrosSsh/DetalleRubroSsh/38.%20Programas%20Ofrecidos"
  },
  {
    nombre: "39 Actas y Resoluciones del Comité de Transparencia",
    icono: "ActasResolucionesComiteTransparencia.png",
    formatos: [
      {
        nombre: "Informe de Sesiones",
        xlsx: "f39_a",
        html: "f39_a",
      },
      {
        nombre: "Informe de Resoluciones",
        xlsx: "f39_b",
        html: "f39_b",
      },
      {
        nombre: "Actas Integrantes del Comité",
        xlsx: "f39_c",
        html: "f39_c",
      },
      {
        nombre: "Calendario de Sesiones del comité",
        xlsx: "f39_d",
        html: "f39_d",
      },
    ],
    url: "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/39.%20Actas%20y%20Resoluciones%20del%20Comit%C3%A9%20de%20Transparencia",
  },
  {
    nombre:
      "40 Evaluacion y Encuesta a Programas Financiados con Recursos Públicos",
    icono: "EvaluacionencuestasProgramas.png",
    formatos: [
      {
        nombre: "Evaluaciones",
        xlsx: "f40_a",
        html: "f40_a",
      },
      {
        nombre: "Encuestas",
        xlsx: "f40_b",
        html: "f40_b",
      },
    ],
    url: "https://hacienda.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/40.Evaluacion%20y%20Encuestas%20a%20Programas%20Financiados%20con%20Recursos%20P%C3%BAblicos",
  },
  {
    nombre: "41. Estudios Pagados con Recursos Públcos",
    icono: "EstudiosPagadosRecursosPublicos.png",
    formatos: [
      {
        nombre: "Estudios Pagados con Recursos Públicos ",
        xlsx: "f41",
        html: "f41",
      },
    ],
  },
  {
    nombre: "42 Listados Jubilados y Pensionados",
    icono: "ListadoJubiladosPencionados.png",
    formatos: [
      {
        nombre: "Jubilados y Pensionados Leyenda",
        xlsx: "f42a",
        html: "f42a",
      },
      {
        nombre: "Jubilados y Pensionados Listado",
        xlsx: "f42b",
        html: "f42b",
      },
    ],
    url: "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/42.%20Listados%20Jubilados%20y%20Pensionados",
  },
  {
    nombre: "43 Ingresos Recibidos por Cualquier Concepto",
    icono: "IngresosRecibidosCualquierConcepto.png",
    formatos: [
      {
        nombre: "Ingresos Recibidos",
        xlsx: "f43a",
        html: "f43a",
      },
      {
        nombre: "Responsables de Recibir los Ingresos",
        xlsx: "f43b",
        html: "f43b",
      },
    ],
  },
  {
    nombre: "44 Donaciones Hechas a Terceros",
    icono: "DonacionesHechasTerceros.png",
    formatos: [
      {
        nombre: "Donaciones en dinero y en especie realizadas",
        xlsx: "f44",
        html: "f44",
      },
      // {
      //   nombre: "Donaciones hechas a Terceros en Dineros",
      //   xlsx: "f44a",
      //   html: "f44a",
      // },
      // {
      //   nombre: "Donaciones Hechas a Terceros en Especie",
      //   xlsx: "f44b",
      //   html: "f44b",
      // },
    ],
  },
  {
    nombre: "45 Catálogo Documental y Guia de Archivos",
    icono: "Viaticos.png",
    formatos: [
      {
        nombre: "Catálogo de Disposición y Guía de Archivos",
        xlsx: "f45a",
        html: "f45a",
      },
      {
        nombre: "Índice de expedientes clasificados como reservados",
        xlsx: "f45b",
        html: "f45b",
        urlXlsx: "https://s-contraloria.hidalgo.gob.mx/Transparencia/xlsx/a69_f45b_SC.xlsx",
        urlHTML: "https://s-contraloria.hidalgo.gob.mx/Transparencia/htm/a69_f45b_SC.htm",
      },
      {
        nombre: "Instrumentos Archivísticos",
        xlsx: "f45c",
        html: "f45c",
      },
    ],
  },
  {
    nombre:
      "46 Actas de Sesiones, Opiniones y Recomendaciones de Consejos Consultivos",
    icono: "CatalogoDocumentalGuiaArchivos.png",
    formatos: [
      {
        nombre: "Actas del Consejo Consultivo Ciudadano",
        xlsx: "f46a",
        html: "f46a",
      },
      {
        nombre: "Opiniones del Consejo Consultivo Ciudadano",
        xlsx: "f46b",
        html: "f46b",
      },
    ],
    url: "https://s-turismo.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/46.Actas%20de%20Sesiones,%20Opiniones%20y%20Recomendaciones%20de%20Consejos%20Consultivos",
  },
  {
    nombre: "47 Solicitud de Intervecion de  Comunicaciones",
    icono: "SolicitudIntervencionComunicaciones.png",
    formatos: [
      {
        nombre: "Intervenciones de comunicaciones",
        xlsx: "f47_a",
        html: "f47_a",
      },
      {
        nombre: "Localización Geográfica",
        xlsx: "f47_b",
        html: "f47_b",
      },
      {
        nombre:
          "Mensaje de Solicitudes a empresas para la intervención de comunicaciones privadas",
        xlsx: "f47_c",
        html: "f47_c",
      },
    ],
    url: "https://procuraduria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Solicitud%20de%20Intervecion%20de%20%20Comunicaciones",
  },
  {
    nombre: "48 Información de Utilidad o Relevante",
    icono: "InformacionUtilidadRelevante.png",
    formatos: [
      {
        nombre: "Interés Público",
        xlsx: "f48a",
        html: "f48a",
      },
      {
        nombre: "Preguntas Frecuentes",
        xlsx: "f48b",
        html: "f48b",
      },
      {
        nombre: "Transparencia Proactiva",
        xlsx: "f48c",
        html: "f48c",
      },
    ],
  },
];
