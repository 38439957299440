// import react from "react";
import "./recibo.css";

function Recibo() {
  return (
    <div className="container pt-4 pb-5">
      <div className="secretaria funciones mb-4">
        <h3 className="title">RECIBO</h3>
        <hr className="hr-gob" />
        {/* Add an image this is the src: /CAASIM/public/assets/imgs/recibo-tut/CAASIM-RECIBO-PNG.png */}
        <h5>Titulo</h5>
        <img
          src="/assets/imgs/recibo-tut/CAASIM-RECIBO-PNG.png"
          alt="Recibo"
          className="img-fluid"
        />
        <h5 className="mt-4">Subtitulo</h5>
        <p>Datos datos datos</p>
        <p>Datos datos datos</p>
      </div>

    </div>
  );
}

export default Recibo;
