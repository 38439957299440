import {
  inv01,
  inv02,
  inv03,
  inv04,
  inv05,
  inv06,
  inv07,
  inv08,
  inv09,
  inv10,
  itp01,
  inv11,
  itp02,
} from "../../json/anioInvitaciones/2024/anioInvitacion2024";

export const obtenerDatosSeleccionados = (selectedItemIndex) => {
  let datosSeleccionados = [];

  switch (selectedItemIndex) {
    case "CAASIM-HGO-INV3-OP-01-2024":
      datosSeleccionados = inv01;
      break;
    // create cases for 02 to 10
    case "CAASIM-HGO-INV3-OP-02-2024":
      datosSeleccionados = inv02;
      break;
    case "CAASIM-HGO-INV3-OP-03-2024":
      datosSeleccionados = inv03;
      break;
    case "CAASIM-HGO-INV3-OP-04-2024":
      datosSeleccionados = inv04;
      break;
    case "CAASIM-HGO-INV3-OP-05-2024":
      datosSeleccionados = inv05;
      break;
    case "CAASIM-HGO-INV3-OP-06-2024":
      datosSeleccionados = inv06;
      break;
    case "CAASIM-HGO-INV3-OP-07-2024":
      datosSeleccionados = inv07;
      break;
    case "CAASIM-HGO-INV3-OP-08-2024":
      datosSeleccionados = inv08;
      break;
    case "CAASIM-HGO-INV3-OP-09-2024":
      datosSeleccionados = inv09;
      break;
    case "CAASIM-HGO-INV3-OP-10-2024":
      datosSeleccionados = inv10;
      break;
    case "INVITACION ITP-01-2024":
      datosSeleccionados = itp01;
      break;
    case "CAASIM-HGO-INV3-OP-11-2024":
      datosSeleccionados = inv11;
      break;
    case "INVITACION ITP-02-2024":
      datosSeleccionados = itp02;
      break;
    default:
      datosSeleccionados = [];
  }

  return datosSeleccionados;
};
