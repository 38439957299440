export const invitaciones2024 = [
  {
    titulo: "CAASIM-HGO-INV3-OP-01-2024",
  },
  {
    titulo: "CAASIM-HGO-INV3-OP-02-2024",
  },
  {
    titulo: "CAASIM-HGO-INV3-OP-03-2024",
  },
  {
    titulo: "CAASIM-HGO-INV3-OP-04-2024",
  },
  {
    titulo: "CAASIM-HGO-INV3-OP-05-2024",
  },
  {
    titulo: "CAASIM-HGO-INV3-OP-06-2024",
  },
  {
    titulo: "CAASIM-HGO-INV3-OP-07-2024",
  },
  {
    titulo: "CAASIM-HGO-INV3-OP-08-2024",
  },
  {
    titulo: "CAASIM-HGO-INV3-OP-09-2024",
  },
  {
    titulo: "CAASIM-HGO-INV3-OP-10-2024",
  },
  {
    titulo: "INVITACION ITP-01-2024",
  },
  {
    titulo: "CAASIM-HGO-INV3-OP-11-2024",
  },
  {
    titulo: "INVITACION ITP-02-2024"
  }
];
